import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {EventEmitter} from "events";
import {session} from "../../api/ApiSession";
import {PORTAL} from "../../utils/Portal";
import {usePreprod} from "../utils/helper";
import {ErrorResponse} from "./http/ErrorResponse";
import {HttpContentType} from "./http/HttpContentType";
import {HttpStatusCode} from "./http/HttpStatusCode";
import {Model} from "./interface/Model";
import {PartnerAuthToken} from "./model/account/PartnerAuthToken";
import {ApiModel} from "./model/ApiModel";
import {Cookie} from "./model/Cookie";
import {QueryString} from "./model/QueryString";

export enum SirdataApiEvent {
    eventUnauthorizedAccountRequest = "unauthorized_account_request",
    eventUnauthorizedRequest = "unauthorized_request",
    eventNotFound = "not_found",
    eventInternalServer = "internal_server"
}

export type HttpRequestOptions = {
    contentType?: HttpContentType | string;
    responseType?: string;
    queryParams?: {};
    newModelParams?: any;
};

export class CommonApiClient {
    public events: EventEmitter;
    protected httpClient: AxiosInstance;
    protected HEADER_X_AUTH_TOKEN = "x-auth-token";

    constructor(version?: number) {
        this.events = new EventEmitter();

        const clientUrl = usePreprod ? "https://gateway-preprod.sirdata.io" : "https://gateway.sirdata.io";
        this.httpClient = axios.create({
            baseURL: `${clientUrl}/api/v${version || "1"}/public`,
            maxRedirects: 2
        } as AxiosRequestConfig);

        this.httpClient.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.isAxiosError) {
                let err = error as AxiosError;
                if (err.response && err.response.status === HttpStatusCode.UNAUTHORIZED) {
                    if (err.response.data === "Unauthorized") {
                        this.events.emit(SirdataApiEvent.eventUnauthorizedRequest, err);
                        return Promise.reject(err);
                    }
                    if (err.request.responseURL && (err.request.responseURL.indexOf("/account") !== -1)) {
                        this.events.emit(SirdataApiEvent.eventUnauthorizedAccountRequest, err);
                    }
                    return Promise.reject(err);
                }
                if (err.response && err.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                    this.events.emit(SirdataApiEvent.eventInternalServer, err);
                    return Promise.reject(err);
                }
                return Promise.reject(err);
            }
        });
    }

    buildRequestConfig(options: any, contentType?: string, body?: any): AxiosRequestConfig {
        if (!contentType && body) {
            if (body instanceof ArrayBuffer) contentType = HttpContentType.OCTET_STREAM;
            else if (body instanceof FormData) contentType = HttpContentType.FORM_DATA;
            else contentType = HttpContentType.JSON;
        }
        options = options || {};
        options["headers"] = options["headers"] || {};
        if (contentType !== undefined && options["headers"]["Content-Type"] === undefined) {
            options["headers"]["Content-Type"] = contentType;
        }
        const sessionToken = Cookie.read(session._tokenCookie);
        if (sessionToken) {
            options["headers"][this.HEADER_X_AUTH_TOKEN] = sessionToken;
        }
        return options as AxiosRequestConfig;
    }

    addPlaceholders(path: string): string {
        return path.replace("{partnerId}", session.partnerId?.toString() || "");
    }

    formatResponseData(resp: AxiosResponse, model?: new (o?: any) => ApiModel, options?: HttpRequestOptions) {
        if (!model) return resp.data;

        if (Array.isArray(resp.data)) {
            const list: Model[] = [];
            for (let m of resp.data) {
                let o = new model(options?.newModelParams);
                o.load(m);
                list.push(o);
            }
            return list;
        }

        let o = new model(options?.newModelParams);
        o.load(resp.data);
        return o;
    }

    async checkLogged(): Promise<boolean> {
        try {
            if (!Cookie.read(session._tokenCookie)) {
                return false;
            }

            let opts = this.buildRequestConfig(undefined);
            const resp = await this.httpClient.get("/auth/check-logged", opts);

            if (resp.status === 200) {
                session.setToken(resp.headers[this.HEADER_X_AUTH_TOKEN]);
                return true;
            } else {
                session.setToken(undefined);
                return false;
            }
        } catch (e) {
            Cookie.remove(session._tokenCookie);
            session.setToken(undefined);
            return false;
        }
    }

    async loginWithToken(tmpToken: string) {
        try {
            if (!tmpToken) return;
            const resp = await this.httpClient.post("/auth/sso-token-validate", {token: tmpToken, origin: PORTAL.origin.name});
            session.setToken(this.formatResponseData(resp, PartnerAuthToken).auth_token);
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }

    async logout(): Promise<void> {
        await this.post("/auth/logout");
        session.setToken(undefined);
    }

    public async get<T extends ApiModel>(path: string, model?: new (o?: any) => T, options?: HttpRequestOptions): Promise<any> {
        let opts: any = {...options};
        if (!!options?.queryParams) {
            path += (path.includes("?") ? "&" : "?") + QueryString.build(options.queryParams);
        }
        try {
            let config = this.buildRequestConfig(opts);
            const resp = await this.httpClient.get(this.addPlaceholders(path), config);
            return this.formatResponseData(resp, model, options);
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }

    public async post<T extends ApiModel>(path: string, body?: any, model?: new (o?: any) => T, options?: HttpRequestOptions): Promise<any> {
        try {
            let config = this.buildRequestConfig({}, options?.contentType, body);
            const resp = await this.httpClient.post(this.addPlaceholders(path), body && body instanceof ApiModel ? body.toJson() : body, config);
            return this.formatResponseData(resp, model, options);
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }

    public async patch<T extends ApiModel>(path: string, body?: any, model?: new (o?: any) => T, options?: HttpRequestOptions): Promise<any> {
        try {
            let config = this.buildRequestConfig({}, options?.contentType, body);
            const resp = await this.httpClient.patch(this.addPlaceholders(path), body && body instanceof ApiModel ? body.toJson() : body, config);
            return this.formatResponseData(resp, model, options);
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }

    public async put<T extends ApiModel>(path: string, body?: any, model?: new (o?: any) => T, options?: HttpRequestOptions): Promise<any> {
        try {
            let config = this.buildRequestConfig({}, options?.contentType, body);
            const resp = await this.httpClient.put(this.addPlaceholders(path), body && body instanceof ApiModel ? body.toJson() : body, config);
            return this.formatResponseData(resp, model, options);
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }

    public async delete(path: string, body?: ApiModel): Promise<Model> {
        let opts: any = {};
        if (!!body) opts["data"] = body.getJsonParameters();
        try {
            let config = this.buildRequestConfig(opts);
            const resp = await this.httpClient.delete(this.addPlaceholders(path), config) as AxiosResponse<Model>;
            return resp.data;
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }
}

import {Rest} from "./Rest";
import {UserNotification} from "../model/notification/UserNotification";
import {Locale} from "../../utils/Locale";
import {PORTAL} from "../../../utils/Portal";
import {Origin} from "../model/Origin";
import {UserNotificationType} from "../model/notification/UserNotificationType";

export class RestUserNotifications extends Rest {
    rootPath = "/frontend-api/notification";

    getHeaderNotifications(): Promise<UserNotification[]> {
        let services: string[] = [PORTAL.origin.service];
        if (!PORTAL.isInternal) {
            services.push(Origin.ACCOUNT.service);
        }
        return this._client.get(`${this.rootPath}?type=${UserNotificationType.DEFAULT}&services=${services.join(",")}&locale=${Locale.getCurrentLocale()}`, UserNotification) as Promise<UserNotification[]>;
    }

    getSliderNotifications(): Promise<UserNotification[]> {
        return this._client.get(`${this.rootPath}?type=${UserNotificationType.SLIDER}&services=${PORTAL.origin.service}&locale=${Locale.getCurrentLocale()}`, UserNotification) as Promise<UserNotification[]>;
    }

    getModalNotifications(): Promise<UserNotification[]> {
        return this._client.get(`${this.rootPath}?type=${UserNotificationType.MODAL}&services=${PORTAL.origin.service}&locale=${Locale.getCurrentLocale()}`, UserNotification) as Promise<UserNotification[]>;
    }

    markAsRead(notificationId: number) {
        return this._client.post(`${this.rootPath}/${notificationId}/mark-read`);
    }

    markAsUnread(notificationId: number) {
        return this._client.post(`${this.rootPath}/${notificationId}/mark-unread`);
    }

    remove(notificationId: number) {
        return this._client.delete(`${this.rootPath}/${notificationId}`);
    }
}

import React, {Dispatch, FormEvent, SetStateAction, useCallback, useState} from "react";
import {Alert, AlertSeverity, FieldBlock, Form, FormLayoutRows} from "@sirdata/ui-lib";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {PaymentService} from "../../api/model/payment/PaymentService";
import {searchParamSuccess, TranslationPortalFile} from "../../utils/constants";
import {session} from "../../api/ApiSession";
import {useTranslation} from "react-i18next";

type OnboardingPaymentFormProps = {
    onSubmit: Dispatch<SetStateAction<boolean>>;
    onSuccess: () => void;
}

export const ONBOARDING_PAYMENT_FORM_ID = "onboarding-payment-form";

const OnboardingPaymentForm: React.FC<OnboardingPaymentFormProps> = ({onSubmit, onSuccess}) => {
    const stripe = useStripe();
    const elements = useElements();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);

    const [apiError, setApiError] = useState("");

    const handleSubmit = useCallback(async (event: FormEvent) => {
        event.preventDefault();
        setApiError("");
        onSubmit(true);

        if (!stripe || !elements) {
            return;
        }

        const {error: submitError} = await elements.submit();
        if (submitError) {
            onSubmit(false);
            return;
        }

        try {
            const loggedAccount = await session.getAccount();
            const partnerBillingInfo = await session.restBilling.getPartnerBillingInfo();
            const setupPaymentMethod = await session.restPayment.createSetupPaymentMethod(PaymentService.STRIPE);

            const {setupIntent} = await stripe.confirmSetup({
                elements: elements,
                clientSecret: setupPaymentMethod.stripe.client_secret,
                confirmParams: {
                    return_url: window.location.href + searchParamSuccess,
                    payment_method_data: {
                        billing_details: {
                            email: loggedAccount.email,
                            address: {
                                line1: partnerBillingInfo.address,
                                line2: null,
                                city: partnerBillingInfo.city,
                                country: null,
                                postal_code: partnerBillingInfo.postal_code,
                                state: null
                            }
                        }
                    }
                },
                redirect: "if_required"
            });

            if (setupIntent && setupIntent.status === "succeeded") {
                onSuccess();
                return;
            }

            setApiError("stripe.confirm_setup_failed");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                setApiError(e.statusCode === 409 ? "payment_method_already_exist" : "default");
            }
        } finally {
            onSubmit(false);
        }
    }, [stripe, elements, onSubmit, onSuccess]);

    return (
        <FormLayoutRows>
            {apiError &&
                <Alert text={t(`error.${apiError}`, t("error.default"))} severity={AlertSeverity.DANGER}/>
            }
            <Form id={ONBOARDING_PAYMENT_FORM_ID} onSubmit={handleSubmit}>
                <FieldBlock label={textOnboarding("register_payment.label.payment_method")} tooltip={textOnboarding("register_payment.tooltip.payment_method")}>
                    <PaymentElement
                        options={{
                            layout: {
                                type: "tabs",
                                defaultCollapsed: false
                            },
                            fields: {
                                billingDetails: {
                                    address: "never",
                                    email: "never"
                                }
                            }
                        }}
                    />
                </FieldBlock>
            </Form>
        </FormLayoutRows>
    );
};

export default OnboardingPaymentForm;

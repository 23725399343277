import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import "./utils/ChartJS";

import PrivateRoute from "./component/guard/PrivateRoute";
import {AccountType} from "./common/api/model/account/AccountType";
import {Authorization} from "./api/model/account/Authorization";
import {
    Accounts,
    ActivateService,
    ApiTokens,
    BillingInfo,
    BillingInvoices,
    ConfirmEmail,
    Credentials,
    ForgottenPassword,
    Home,
    Information,
    InvitationAccept,
    LegalNotice,
    Login,
    Register,
    RegisterBilling,
    RegisterInfo,
    RegisterOrigin,
    RegisterPayment,
    ResetPassword,
    SirdataProducts,
    TermsOfUse,
    TrafficDaily,
    TrafficGlobal
} from "./screen";
import {
    pathAccessAccounts,
    pathAccessApiTokens,
    pathActivateService,
    pathBillingInfo,
    pathBillingInvoices,
    pathConfirmEmail,
    pathCredentials,
    pathForgottenPassword,
    pathInformation,
    pathInvitationAccept,
    pathRegister,
    pathRegisterBilling,
    pathRegisterInfo,
    pathRegisterOrigin,
    pathRegisterPayment,
    pathResetPassword,
    pathSirdataProducts,
    pathTermsOfUse,
    pathTrafficDaily,
    pathTrafficGlobal
} from "./utils/constants";
import {pathHome, pathLegalNotice, pathLogin} from "./common/utils/constants";
import {PortalContextProvider} from "./common/context/PortalContext";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";
import {session} from "./api/ApiSession";

const App: FunctionComponent = () => {
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Navigate to={pathLogin}/>}/>
                            <Route path={pathLogin} element={<Login/>}/>
                            <Route path={pathForgottenPassword} element={<ForgottenPassword/>}/>
                            <Route path={pathResetPassword} element={<ResetPassword/>}/>
                            <Route path={pathRegister} element={<Register/>}/>
                            <Route path={pathInvitationAccept} element={<InvitationAccept/>}/>
                            <Route path={pathConfirmEmail} element={<ConfirmEmail/>}/>
                            <Route path={pathLegalNotice} element={<LegalNotice/>}/>
                            <Route path={pathTermsOfUse} element={<TermsOfUse/>}/>

                            <Route path={pathRegisterInfo} element={<PrivateRoute children={<RegisterInfo/>}/>}/>
                            <Route path={pathRegisterOrigin} element={<PrivateRoute children={<RegisterOrigin/>}/>}/>
                            <Route path={pathRegisterBilling} element={<PrivateRoute children={<RegisterBilling/>}/>}/>
                            <Route path={pathRegisterPayment} element={<PrivateRoute children={<RegisterPayment/>}/>}/>
                            <Route path={pathHome} element={<PrivateRoute children={<Home/>}/>}/>
                            <Route path={pathInformation} element={<PrivateRoute children={<Information/>}/>}/>
                            <Route path={pathCredentials} element={<PrivateRoute children={<Credentials/>}/>}/>
                            <Route path={pathSirdataProducts} element={<PrivateRoute children={<SirdataProducts/>}/>}/>
                            <Route
                                path={pathAccessAccounts}
                                element={
                                    <PrivateRoute
                                        children={<Accounts/>}
                                        restrictedAccountType={AccountType.USER}
                                    />}
                            />
                            <Route
                                path={pathAccessApiTokens}
                                element={
                                    <PrivateRoute
                                        children={<ApiTokens/>}
                                        restrictedAccountType={AccountType.USER}
                                    />}
                            />
                            <Route
                                path={pathTrafficDaily}
                                element={
                                    <PrivateRoute
                                        children={<TrafficDaily/>}
                                        restrictedAccountType={AccountType.USER}
                                        requiredAuthorization={Authorization.TRAFFIC}
                                    />}
                            />
                            <Route
                                path={pathTrafficGlobal}
                                element={
                                    <PrivateRoute
                                        children={<TrafficGlobal/>}
                                        restrictedAccountType={AccountType.USER}
                                        requiredAuthorization={Authorization.TRAFFIC}
                                    />}
                            />
                            <Route
                                path={pathBillingInfo}
                                element={
                                    <PrivateRoute
                                        children={<BillingInfo/>}
                                        restrictedAccountType={AccountType.USER}
                                    />}
                            />
                            <Route
                                path={pathBillingInvoices}
                                element={
                                    <PrivateRoute
                                        children={<BillingInvoices/>}
                                        restrictedAccountType={AccountType.USER}
                                    />}
                            />
                            <Route path={pathActivateService} element={<PrivateRoute children={<ActivateService/>}/>}/>

                            <Route path="*" element={<Navigate to={pathLogin}/>}/>
                        </Routes>
                    </BrowserRouter>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;

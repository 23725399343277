import {CommonApiSession} from "../common/api/CommonApiSession";
import {Cookie} from "../common/api/model/Cookie";
import {Country} from "../common/api/model/Country";
import {Origin} from "../common/api/model/Origin";
import {QueryString} from "../common/api/model/QueryString";
import {AccountType} from "../common/api/model/account/AccountType";
import {PartnerAccount} from "./model/account/PartnerAccount";
import {RestApiToken} from "./rest/RestApiToken";
import {RestBilling} from "./rest/RestBilling";
import {RestIncome} from "./rest/RestIncome";
import {RestInvitation} from "./rest/RestInvitation";
import {RestPartner} from "./rest/RestPartner";
import {RestPartnerAccount} from "./rest/RestPartnerAccount";
import {RestPartnerOrganization} from "./rest/RestPartnerOrganization";
import {ApiClient} from "./ApiClient";
import {Account} from "../common/api/model/account/Account";
import {Onboarding} from "../utils/Onboarding";
import {EmailDomainInfos} from "./model/partner-organization/EmailDomainInfos";
import {LicensedProduct} from "./model/account/LicensedProduct";
import {RestPayment} from "./rest/RestPayment";

export enum SESSION_COOKIE {
    ORIGIN = "s_origin",
    GOTO = "s_goto",
}

export class ApiSession extends CommonApiSession {

    _client: ApiClient;
    _clientV2: ApiClient;

    private _origin: Origin = Origin.ACCOUNT;
    private _onboarding: Onboarding = Onboarding.ACCOUNT;
    private _premium: boolean = false;
    private _partnerAccount: PartnerAccount | undefined;
    private _partnerAccounts: PartnerAccount[] | undefined;
    private _countries: Country[] | undefined;
    private _emailDomainInfos: EmailDomainInfos | undefined;

    private _restApiToken: RestApiToken | undefined;
    private _restBilling: RestBilling | undefined;
    private _restIncome: RestIncome | undefined;
    private _restInvitation: RestInvitation | undefined;
    private _restPartner: RestPartner | undefined;
    private _restPartnerAccount: RestPartnerAccount | undefined;
    private _restPartnerOrganization: RestPartnerOrganization | undefined;
    private _restPayment: RestPayment | undefined;

    constructor() {
        super();
        this._client = new ApiClient();
        this._clientV2 = new ApiClient(2);
        this._origin = Origin.forOrigin(Cookie.read(SESSION_COOKIE.ORIGIN)) || Origin.ACCOUNT;
        this._onboarding = Onboarding.forOrigin(this._origin) || Onboarding.ACCOUNT;
    }

    get restApiToken(): RestApiToken {
        return this._restApiToken !== undefined ? this._restApiToken : this._restApiToken = new RestApiToken(this._client);
    }

    get restBilling(): RestBilling {
        return this._restBilling !== undefined ? this._restBilling : this._restBilling = new RestBilling(this._clientV2);
    }

    get restIncome(): RestIncome {
        return this._restIncome !== undefined ? this._restIncome : this._restIncome = new RestIncome(this._client);
    }

    get restInvitation(): RestInvitation {
        return this._restInvitation !== undefined ? this._restInvitation : this._restInvitation = new RestInvitation(this._client);
    }

    get restPartner(): RestPartner {
        return this._restPartner !== undefined ? this._restPartner : this._restPartner = new RestPartner(this._client);
    }

    get restPartnerAccount(): RestPartnerAccount {
        return this._restPartnerAccount !== undefined ? this._restPartnerAccount : this._restPartnerAccount = new RestPartnerAccount(this._client);
    }

    get restPartnerOrganization(): RestPartnerOrganization {
        return this._restPartnerOrganization !== undefined ? this._restPartnerOrganization : this._restPartnerOrganization = new RestPartnerOrganization(this._client);
    }

    get restPayment(): RestPayment {
        return this._restPayment !== undefined ? this._restPayment : this._restPayment = new RestPayment(this._clientV2);
    }

    async isAccountType(accountType: AccountType): Promise<boolean> {
        let account = await this.getPartnerAccount();
        return account.type === accountType;
    }

    clean() {
        super.clean();
        this._partnerAccounts = undefined;
    }

    get origin(): Origin {
        return this._origin;
    }

    set origin(origin: Origin) {
        this._origin = origin;
    }

    get onboarding(): Onboarding {
        return this._onboarding;
    }

    get premium(): boolean {
        return this._premium;
    }

    async login(email: string, password: string, remember: boolean): Promise<ApiSession> {
        await this._client.login(email, password);
        let date = new Date();
        date.setDate(date.getDate() + 30);
        Cookie.write(this._tokenCookie, this.token || "", remember ? date : undefined);
        return this;
    }

    async sendResetPasswordMail(email: string) {
        await this._client.sendResetPasswordMail(email, this._origin.name);
    };

    async resetPassword(token: string, email: string) {
        await this._client.resetPassword(token, email);
    };

    async register(email: string, password: string): Promise<CommonApiSession> {
        const license = LicensedProduct.getDefaultLicense(this.origin, this.premium);
        await this.restPartnerAccount.create(email, password, this.origin, license?.name);
        return this.login(email, password, false);
    }

    async registerWithValidation(email: string, password: string): Promise<void> {
        await this.restPartnerAccount.create(email, password, this.origin);
        return;
    }

    async activateService(origin: Origin): Promise<void> {
        const license = LicensedProduct.getDefaultLicense(origin, this.premium);
        await session.restAccount.activateService(origin, license?.name);
        return session.setLicenseAfterActivation();
    }

    async getAccount(): Promise<Account> {
        const account = await super.getAccount();
        return account as Account;
    }

    async getPartnerAccount(): Promise<PartnerAccount> {
        if (this._partnerAccount === undefined) {
            this._partnerAccount = await this.restPartnerAccount.get();
        }
        return this._partnerAccount;
    }

    setPartnerAccount(partnerAccount: PartnerAccount) {
        this._partnerAccount = partnerAccount;
    }

    async getPartnerAccounts(): Promise<PartnerAccount[]> {
        if (!this._partnerAccounts) {
            this._partnerAccounts = await this.restPartnerAccount.getPartnerAccounts();
        }
        return this._partnerAccounts;
    }

    async getEmailDomainInfos(domain: string): Promise<EmailDomainInfos> {
        if (!this._emailDomainInfos) {
            this._emailDomainInfos = await this.restPartnerOrganization.getEmailDomainInfos(domain);
        }
        return this._emailDomainInfos;
    }

    async getCountries(): Promise<Country[]> {
        if (!this._countries) {
            const countries = await this.restPartnerAccount.getCountries();
            countries.sort((a, b) => (a.name.localeCompare(b.name)));
            this._countries = countries;
        }
        return this._countries;
    }

    saveOrigin(queryString: string, registering?: boolean) {
        const origin = Origin.forOrigin(QueryString.getOrigin(queryString)) || Origin.ACCOUNT;
        const goTo = QueryString.getGoTo(queryString);

        if (origin !== Origin.ACCOUNT) {
            this._origin = origin;
            Cookie.write(SESSION_COOKIE.ORIGIN, origin.name);

            if (goTo) {
                Cookie.write(SESSION_COOKIE.GOTO, goTo);
            }

            if (registering) {
                if (origin === Origin.CMP) {
                    this._premium = !!QueryString.getParam(queryString, "premium");
                }
            }
        }
    }

    saveOnboarding() {
        this._onboarding = Onboarding.forOrigin(this._origin) || Onboarding.ACCOUNT;
    }

    async setLicenseAfterRegister() {
        const defaultLicense = LicensedProduct.getDefaultLicense(this.origin, this.premium);
        if (defaultLicense) {
            await this.restLicense.post(defaultLicense.id, true);
        }
    }

    async setLicenseAfterActivation() {
        const defaultLicense = LicensedProduct.getDefaultLicense(this.origin, this.premium);
        if (!defaultLicense) return;

        const partnerLicenses = await this.restLicense.list();
        const existingLicense = partnerLicenses.find((it) => it.license.name === defaultLicense.name);

        if (!existingLicense) {
            await this.restLicense.post(defaultLicense.id, true);
        } else if (!existingLicense.active) {
            await this.restLicense.activate(defaultLicense.id);
        }
    }
}

export const session = new ApiSession();

import React, {useState} from "react";
import {AlertSeverity, Box, BoxSpacing, ButtonLinkCancel, ButtonSave, FieldBlock, FlexContentDirection, FormLayoutButtons, FormLayoutColumns, FormLayoutMention, FormLayoutRows, FormLayoutTitle, InputPhone, InputPhoneCountry, InputPhoneLocale, InputText} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {ButtonUpdate, FormErrorMessage} from "../../../component/snippet";
import {CountryCode} from "../../../common/api/model/Country";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {Locale} from "../../../common/utils/Locale";
import {PartnerAccount} from "../../../api/model/account/PartnerAccount";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";

type FormChangeAccountInfoProps = {
    initPartnerAccount: PartnerAccount;
}

type InfoForm = {
    last_name: string;
    first_name: string;
    phone: string;
    phone_region: string;
}

const FormChangeAccountInfo: React.FC<FormChangeAccountInfoProps> = ({initPartnerAccount}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textInformation} = useTranslation(TranslationPortalFile.INFORMATION);

    const [partnerAccount, setPartnerAccount] = useState<PartnerAccount>(initPartnerAccount);
    const [infoForm, setInfoForm] = useState<InfoForm>({
        last_name: initPartnerAccount.last_name,
        first_name: initPartnerAccount.first_name,
        phone: initPartnerAccount.phone,
        phone_region: initPartnerAccount.phone_region
    });

    const [errors, setErrors] = useState<{ phone?: boolean }>({phone: false});
    const [isOpenForm, setOpenForm] = useState(false);

    const handleSubmit = async () => {
        try {
            setErrors({});

            const sessionPartnerAccount = await session.getPartnerAccount();

            const newPartnerAccount = new PartnerAccount({
                ...sessionPartnerAccount,
                last_name: infoForm.last_name,
                first_name: infoForm.first_name,
                phone: infoForm.phone,
                phone_region: infoForm.phone_region || CountryCode.FRANCE.code
            });

            await session.restPartnerAccount.update(newPartnerAccount);
            session.setPartnerAccount(newPartnerAccount);

            setPartnerAccount(newPartnerAccount);
            setOpenForm(false);
            UIEventManager.alert(textInformation("message.submit_success"), AlertSeverity.SUCCESS);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode === HttpStatusCode.BAD_REQUEST && e.message.includes("invalid phone number")) {
                    setErrors((prevState) => ({...prevState, phone: true}));
                } else {
                    UIEventManager.alert(t(`error.${getApiErrorTranslationKey(e.message)}`), AlertSeverity.DANGER);
                }
            }
        }
    };

    const handleCancel = () => {
        setInfoForm(partnerAccount);
        setOpenForm(false);
    };

    const hasEmptyField = () => !infoForm.last_name || !infoForm.first_name || !infoForm.phone || !infoForm.phone_region;

    return (
        <Box spacing={BoxSpacing.LARGE}>
            <FormLayoutRows>
                <FormLayoutTitle>{textInformation("account_info_form.title")}</FormLayoutTitle>
                {isOpenForm ?
                    <>
                        <FieldBlock label={textInformation("account_info_form.field.account_type")}>
                            {textInformation(`account_info_form.account_types.${partnerAccount.type}`)}
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textInformation("account_info_form.field.last_name")} required>
                                <InputText
                                    placeholder={textInformation("account_info_form.placeholder.last_name")}
                                    value={infoForm.last_name}
                                    onChange={(value) => setInfoForm((prevState) => ({...prevState, last_name: value}))}
                                    autoFocus
                                />
                            </FieldBlock>
                            <FieldBlock label={textInformation("account_info_form.field.first_name")} required>
                                <InputText
                                    placeholder={textInformation("account_info_form.placeholder.first_name")}
                                    value={infoForm.first_name}
                                    onChange={(value) => setInfoForm((prevState) => ({...prevState, first_name: value}))}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock
                            label={textInformation("account_info_form.field.phone")}
                            content={{direction: FlexContentDirection.COLUMN}}
                            required
                        >
                            <InputPhone
                                placeholder={textInformation("account_info_form.placeholder.phone")}
                                value={infoForm.phone}
                                onChange={(value: string) => setInfoForm((prevState) => ({...prevState, phone: value}))}
                                locale={Locale.isFrench() ? InputPhoneLocale.FRENCH : InputPhoneLocale.ENGLISH}
                                country={infoForm.phone_region as InputPhoneCountry}
                                onCountryChange={(value) => setInfoForm((prevState) => ({...prevState, phone_region: value || ""}))}
                            />
                            {!!errors.phone &&
                                <FormLayoutMessage message={t(`error.${FormErrorMessage.PHONE_INVALID}`)} severity={AlertSeverity.DANGER}/>
                            }
                        </FieldBlock>
                        <FormLayoutMention/>
                        <FormLayoutButtons>
                            <ButtonLinkCancel onClick={handleCancel}/>
                            <ButtonSave onClick={handleSubmit} disabled={hasEmptyField()}/>
                        </FormLayoutButtons>
                    </> :
                    <>
                        <FieldBlock label={textInformation("account_info_form.field.account_type")}>
                            {textInformation(`account_info_form.account_types.${partnerAccount.type}`)}
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textInformation("account_info_form.field.last_name")}>
                                {partnerAccount.last_name}
                            </FieldBlock>
                            <FieldBlock label={textInformation("account_info_form.field.first_name")}>
                                {partnerAccount.first_name}
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textInformation("account_info_form.field.phone")}>
                            {partnerAccount.phone}
                        </FieldBlock>
                        <FormLayoutButtons>
                            <ButtonUpdate onClick={() => setOpenForm(true)}/>
                        </FormLayoutButtons>
                    </>
                }
            </FormLayoutRows>
        </Box>
    );
};

export default FormChangeAccountInfo;

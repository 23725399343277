import {Rest} from "./Rest";
import {MailRequest} from "../model/mailing/MailRequest";

export class RestMailing extends Rest {
    rootPath = "/frontend-api/mailing";

    sendMail(request: MailRequest): Promise<void> {
        return this._client.post(`${this.rootPath}/send`, request);
    }
}

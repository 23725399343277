import moment from "moment";
import React from "react";
import {Box, BoxRadius, BoxSpacing, ButtonLink, Color, Formatter, Icon, Paragraph} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";

const TRIAL_DAYS = 14;

const TrialInformation: React.FC = () => {
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);

    return (
        <Box radius={BoxRadius.LG} spacing={BoxSpacing.LARGE} cssClass="trial-information">
            <span className="h2">{textOnboarding("trial_information.title")}</span>
            <div className="trial-information__step">
                <div>
                    <Icon name="sell" outlined colorIcon={Color.CYAN}/>
                </div>
                <div>
                    <div className="trial-information__step__title trial-information__step__title--highlight">
                        {textOnboarding("trial_information.trial_start.title", {count: TRIAL_DAYS})}
                    </div>
                    <Paragraph>
                        {textOnboarding("trial_information.trial_start.description", {
                            startDate: Formatter.formatDate(moment()),
                            endDate: Formatter.formatDate(moment().add(TRIAL_DAYS, "days"))
                        })}
                    </Paragraph>
                </div>
            </div>
            <div className="trial-information__step">
                <div>
                    <Icon name="notifications_active" outlined colorIcon={Color.OCEAN}/>
                </div>
                <div>
                    <div className="trial-information__step__title">
                        {textOnboarding("trial_information.trial_alerting.title")}
                    </div>
                    <Paragraph>
                        {textOnboarding("trial_information.trial_alerting.description")}
                    </Paragraph>
                </div>
            </div>
            <div className="trial-information__step">
                <div>
                    <Icon name="credit_card" outlined colorIcon={Color.OCEAN}/>
                </div>
                <div>
                    <div className="trial-information__step__title">
                        {textOnboarding("trial_information.trial_end.title")}
                    </div>
                    <Paragraph>
                        {textOnboarding("trial_information.trial_end.description")}
                    </Paragraph>
                    <ButtonLink
                        cssClass="trial-information__step__link"
                        onClick={() => window.open(textOnboarding("trial_information.faq.link"), "_blank")}
                    >
                        {textOnboarding("trial_information.faq.label")}
                    </ButtonLink>
                </div>
            </div>
        </Box>
    );
};

export default TrialInformation;

import {Color, LayoutColumns, LayoutRows, MainContentPageHeader, MainContentStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Account} from "../common/api/model/account/Account";
import {AccountType} from "../common/api/model/account/AccountType";
import {FormChangeAccountInfo, FormChangePartnerInfo, FormChangePrivacy} from "../component/snippet";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {PartnerAccount} from "../api/model/account/PartnerAccount";
import {session} from "../api/ApiSession";
import {TranslationPortalFile} from "../utils/constants";

import "react-phone-number-input/style.css";

function Information() {
    const {t: textInformation} = useTranslation(TranslationPortalFile.INFORMATION);
    const [loggedAccount, setLoggedAccount] = useState<Account>();
    const [partnerAccount, setPartnerAccount] = useState<PartnerAccount>();

    useEffect(() => {
        (async function () {
            try {
                setLoggedAccount(await session.getAccount());
                setPartnerAccount(await session.getPartnerAccount());
            } catch (e) {
            }
        })();
    }, []);

    if (!partnerAccount || !loggedAccount) return <></>;

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textInformation("title")}
                    icon={{name: "account_circle", colorIcon: Color.CYAN, outlined: true}}
                />
                <LayoutRows>
                    <LayoutColumns>
                        <FormChangePartnerInfo loggedAccount={loggedAccount} initPartnerAccount={partnerAccount}/>
                        <FormChangeAccountInfo initPartnerAccount={partnerAccount}/>
                    </LayoutColumns>
                    {loggedAccount.type === AccountType.OWNER &&
                        <FormChangePrivacy initPartnerAccount={partnerAccount}/>
                    }
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Information;

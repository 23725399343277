import {ApiModel} from "../../../common/api/model/ApiModel";
import {Currency} from "../../../utils/Currency";
import {InvoiceOrigin} from "./InvoiceOrigin";

export class Invoice extends ApiModel {
    id: number = 0;
    product_code: string = "";
    reference: string = "";
    month: string = "";
    total_amount: number = 0;
    currency: Currency = Currency.EURO;
    origin: InvoiceOrigin = InvoiceOrigin.SIRDATA;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

import {PartnerLicense} from "../model/license/PartnerLicense";
import {Rest} from "./Rest";

export class RestLicense extends Rest {
    rootPath = "/frontend-api/license";

    list(): Promise<PartnerLicense[]> {
        return this._client.get(this.rootPath, PartnerLicense) as Promise<PartnerLicense[]>;
    }

    post(licenseId: number, activate: boolean): Promise<PartnerLicense> {
        return this._client.post(`${this.rootPath}/${licenseId}?activate=${activate}`) as Promise<PartnerLicense>;
    }

    activate(licenseId: number): Promise<PartnerLicense> {
        return this._client.post(`${this.rootPath}/${licenseId}/activate`) as Promise<PartnerLicense>;
    }
}

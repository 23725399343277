import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Invoice} from "../../../api/model/billing/Invoice";
import {getSymbolFromCurrency} from "../../../utils/Currency";
import {Action, IconTooltip, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {Formatter} from "../../../common/utils/Formatter";

type InvoiceRowProps = {
    invoice: Invoice;
}

const InvoiceRow: FunctionComponent<InvoiceRowProps> = ({invoice}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const INVOICE_PREFIX = "SIRDATA_INVOICE_";

    const handleDownload = async () => {
        const fileContent = await session.restBilling.getInvoiceFile(invoice);
        let url = window.URL.createObjectURL(fileContent);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${INVOICE_PREFIX}${invoice.reference}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{invoice.month}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{invoice.product_code}</TableColumn>
            <TableColumn>{invoice.reference}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {Formatter.formatNumber(invoice.total_amount / 100, "0,0.00a")} {getSymbolFromCurrency(invoice.currency)}
            </TableColumn>
            <TableColumn>
                <IconTooltip
                    icon={{name: "picture_as_pdf"}}
                    text={textCommon(Action.DOWNLOAD.labelKey)}
                    onClick={handleDownload}
                />
            </TableColumn>
        </TableRow>
    );
};

export default InvoiceRow;

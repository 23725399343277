import {session, SESSION_COOKIE} from "../api/ApiSession";
import {Cookie} from "../common/api/model/Cookie";
import {QueryString} from "../common/api/model/QueryString";
import {usePreprod} from "../common/utils/helper";
import {Origin} from "../common/api/model/Origin";

export const sirdataDataAcquisitionBarterAgreementUrl = `${Origin.CMP.getUrl(usePreprod)}/barter-agreement`;

export function redirectToOrigin(remember: boolean = false): boolean {
    if (!session) return false;

    const origin = session.origin;
    if (origin !== Origin.ACCOUNT) {
        try {
            session.restAccount.getTmpAuthToken()
                .then((data) => {
                    const queryString = [
                        {key: QueryString.TOKEN, value: data.token},
                        {key: QueryString.GOTO, value: Cookie.read(SESSION_COOKIE.GOTO)},
                        {key: QueryString.REMEMBER, value: +remember},
                        {key: QueryString.REGISTER, value: sessionStorage.getItem("register")}
                    ].filter(({value}) => value).map(({key, value}) => `${key}=${value}`).join("&");

                    sessionStorage.removeItem("register");
                    Cookie.remove(SESSION_COOKIE.ORIGIN);
                    Cookie.remove(SESSION_COOKIE.GOTO);

                    const ssoUrl = origin.getSsoUrl(usePreprod);
                    window.location.href = `${ssoUrl}?${queryString}`;
                });
            return true;
        } catch (e) {
            return false;
        }
    }
    return false;
}

export function redirectToOriginAccess(activationStatus: string) {
    const origin = session.origin;
    if (origin !== Origin.ACCOUNT) {
        Cookie.remove(SESSION_COOKIE.ORIGIN);
        const accessUrl = origin.getAccessUrl(usePreprod);
        window.location.href = `${accessUrl}?${activationStatus}`;
    }
}

export function getTermsOfUseUrlForOrigin(origin: Origin): string {
    const url = origin.getUrl(usePreprod);

    switch (origin) {
        case Origin.ACCOUNT:
            return "./terms-of-use";

        case Origin.CMP:
            return url + "/terms-of-sale";

        case Origin.GTM_SERVER_SIDE:
            return url + "/terms-and-conditions";

        case Origin.HELPER:
            return url + "/terms-and-conditions";

        default:
            return "";
    }
}

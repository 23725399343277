import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationCommonFile} from "../../utils/constants";
import {session} from "../../../api/ApiSession";
import {CONTACT_TOPICS, ContactTopic} from "../../api/model/account/ContactTopic";
import {Alert, AlertItem, AlertSeverity, Button, ButtonSize, FieldBlock, FormLayoutButtons, FormLayoutColumns, FormLayoutRows, InputEmail, ModalContent, ModalNew, ModalTitle, Paragraph, Select, Textarea} from "@sirdata/ui-lib";
import {UIEventManager} from "../../utils/UIEventManager";
import {LoggedAccount} from "../../api/interface/LoggedAccount";
import {Origin} from "../../api/model/Origin";
import {PORTAL} from "../../../utils/Portal";

type ModalContactForm = {
    topicValue: string;
    topicLabel: string;
    tags: string[];
    message: string;
    onClose?: () => void;
}

const ModalContact: FunctionComponent = () => {
    const {t: textContact} = useTranslation(TranslationCommonFile.CONTACT);
    const [isActive, setActive] = useState<boolean>(false);
    const [onClose, setOnClose] = useState<() => void>(() => {});
    const [message, setMessage] = useState<AlertItem>();

    const [account, setAccount] = useState<LoggedAccount>();

    const [availableContactForms, setAvailableContactForms] = useState<ModalContactForm[]>([]);
    const [currentContactForm, setCurrentContactForm] = useState<ModalContactForm>({topicValue: ContactTopic.GENERAL_QUESTION, topicLabel: "", message: "", tags: []});

    const hasEmptyField = () => !account?.email || !currentContactForm.topicValue || !currentContactForm.message;

    const handleClose = () => {
        setAvailableContactForms([]);
        setCurrentContactForm({topicValue: ContactTopic.GENERAL_QUESTION, topicLabel: "", message: "", tags: []});
        setActive(false);
        setMessage(undefined);
        onClose && onClose();
    };

    const handleSubmit = async () => {
        setMessage(undefined);
        try {
            const topic = currentContactForm.topicLabel;
            const message = currentContactForm.message.replaceAll("\n", "<br/>");
            await session.restPortal.sendContactMessage(topic, message, currentContactForm.tags);

            setMessage({text: textContact("mail_sent"), severity: AlertSeverity.SUCCESS});
            setTimeout(() => handleClose(), 2000);
        } catch (e) {
            setMessage({text: textContact("mail_error"), severity: AlertSeverity.DANGER});
            setTimeout(() => setMessage(undefined), 5000);
        }
    };

    useEffect(() => {
        UIEventManager.addListener(ModalContactUiEvent, (args: ModalContactForm) => {
            const newAvailableContactForms: ModalContactForm[] = CONTACT_TOPICS.map((it) => ({
                topicValue: it,
                topicLabel: textContact(`topic.${it}`),
                message: textContact(`message.${it}`),
                tags: args.tags
            }));

            const selectedTopicValue = args?.topicValue || ContactTopic.GENERAL_QUESTION;
            const existingContactForm = newAvailableContactForms.find(({topicValue}) => topicValue === selectedTopicValue);
            if (existingContactForm) {
                setCurrentContactForm(existingContactForm);
            } else {
                const contactForm: ModalContactForm = {...args};
                newAvailableContactForms.push(contactForm);
                setCurrentContactForm(contactForm);
            }
            setAvailableContactForms(newAvailableContactForms);

            (async function () {
                try {
                    const account = await session.getAccount();
                    setAccount(account);
                } catch (e) {
                }
            })();

            setActive(true);
            setOnClose(() => args.onClose);
        });

        return () => {
            UIEventManager.removeAllListeners(ModalContactUiEvent);
        };
    }, [availableContactForms, textContact]);

    return (
        <ModalNew onClose={handleClose} active={isActive}>
            <ModalContent>
                <ModalTitle>{textContact("title")}</ModalTitle>
                <FormLayoutRows>
                    {Origin.CMP.name === PORTAL.origin.name &&
                        <Paragraph>
                            <span dangerouslySetInnerHTML={{__html: textContact("documentation_url.cmp")}}/>
                        </Paragraph>
                    }
                    {Origin.GTM_SERVER_SIDE.name === PORTAL.origin.name &&
                        <Paragraph>
                            <span dangerouslySetInnerHTML={{__html: textContact("documentation_url.gtm_server_side")}}/>
                        </Paragraph>
                    }
                    <FormLayoutColumns>
                        <FieldBlock label={textContact("from.title")}>
                            <InputEmail
                                value={account?.email || ""}
                                onChange={() => {}}
                                disabled
                            />
                        </FieldBlock>
                        <FieldBlock label={textContact("topic.title")}>
                            <Select
                                value={currentContactForm.topicValue}
                                options={availableContactForms.map((it) => ({value: it.topicValue, label: it.topicLabel, form: it}))}
                                onChange={(option) => setCurrentContactForm(option?.form)}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FieldBlock label={textContact("message.title")}>
                        <Textarea
                            placeholder={textContact("message.placeholder")}
                            value={currentContactForm.message}
                            onChange={(value) => setCurrentContactForm((prevState) => ({...prevState, message: value}))}
                            rows={10}
                            autoFocus
                            required
                        />
                    </FieldBlock>
                    <FormLayoutButtons>
                        <Button size={ButtonSize.BIG} disabled={hasEmptyField()} onClick={handleSubmit}>{textContact("send")}</Button>
                    </FormLayoutButtons>
                    {message && <Alert text={message.text} severity={message.severity}/>}
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalContact;
export const ModalContactUiEvent = "ModalContact";

import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {Action, Box, BoxRadius, BoxSpacing, Button, ButtonSize, ButtonStyle, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentSpacing, LayoutRows, MainContentPageHeader, MainContentStyle, StatusLabel, TranslationLibFile} from "@sirdata/ui-lib";
import {Account} from "../common/api/model/account/Account";
import {QueryString} from "../common/api/model/QueryString";
import {Origin} from "../common/api/model/Origin";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {usePreprod} from "../common/utils/helper";
import {pathAccess} from "../common/utils/constants";
import {session} from "../api/ApiSession";
import {TranslationPortalFile} from "../utils/constants";
import {SirdataProduct} from "../utils/SirdataProduct";

function SirdataProducts() {
    const {t: textSirdataProducts} = useTranslation(TranslationPortalFile.SIRDATA_PRODUCTS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [account, setAccount] = useState<Account>();

    useEffect(() => {
        (async () => {
            try {
                const account = await session.getAccount();
                setAccount(account);
            } catch (e) {}
        })();
    }, []);

    const goToOriginAccess = async (origin: Origin) => {
        try {
            const authToken = await session.restAccount.getTmpAuthToken();
            window.open(`${origin.getSsoUrl(usePreprod)}?${QueryString.TOKEN}=${authToken.token}&${QueryString.GOTO}=${pathAccess}`, "_blank");
        } catch (e) {}
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textSirdataProducts("title")}
                    icon={{name: "category", colorIcon: Color.CYAN, outlined: true}}
                />
                <LayoutRows>
                    <FlexContent
                        direction={FlexContentDirection.ROW}
                        spacing={FlexContentSpacing.SMALL}
                        cssClass="sirdata-products"
                        allowWrap
                    >
                        {SirdataProduct.values().map((product) => {
                            const hasActiveService = account?.hasActiveService(product.origin.name);
                            return (
                                <Box
                                    key={product.name}
                                    radius={BoxRadius.MD}
                                    spacing={BoxSpacing.MEDIUM}
                                    cssClass={clsx("sirdata-products__item", {"sirdata-products__item--active": hasActiveService})}
                                >
                                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL}>
                                        <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.SPACE_BETWEEN}>
                                            <span className="sirdata-products__item__title">{textSirdataProducts(`products.${product.name}.title`)}</span>
                                            {hasActiveService &&
                                                <StatusLabel label={textSirdataProducts("status.active")} icon={{name: "circle", colorIcon: Color.GREEN}}/>
                                            }
                                        </FlexContent>
                                        <FlexContent
                                            direction={FlexContentDirection.COLUMN}
                                            alignment={FlexContentAlignment.CENTER}
                                            justify={FlexContentJustify.CENTER}
                                            cssClass="sirdata-products__item__price"
                                        >
                                            <p>{textSirdataProducts(`products.${product.name}.price_from`)}</p>
                                            <span>{textSirdataProducts(`products.${product.name}.price`)}</span>
                                        </FlexContent>
                                        {!hasActiveService &&
                                            <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.CENTER}>
                                                <Button
                                                    size={ButtonSize.BIG}
                                                    style={ButtonStyle.PRIMARY_MIDNIGHT_LIGHT}
                                                    onClick={() => goToOriginAccess(product.origin)}
                                                >
                                                    {product === SirdataProduct.CUSTOMER ? textSirdataProducts("request_activation") : textCommon(Action.ACTIVATE.labelKey)}
                                                </Button>
                                            </FlexContent>
                                        }
                                    </FlexContent>
                                </Box>
                            );
                        })}
                    </FlexContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default SirdataProducts;

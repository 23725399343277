import {Origin} from "../common/api/model/Origin";

export class SirdataProduct {
    static ABCONSENT = new SirdataProduct("abconsent", Origin.CMP);
    static SGTM = new SirdataProduct("sgtm", Origin.GTM_SERVER_SIDE);
    static AUDIENCE = new SirdataProduct("audience", Origin.AUDIENCE);
    static CUSTOMER = new SirdataProduct("customer", Origin.CUSTOMER);

    name: string = "";
    origin: Origin;

    constructor(name: string, origin: Origin) {
        this.name = name;
        this.origin = origin;
    }

    static values(): SirdataProduct[] {
        return [
            SirdataProduct.ABCONSENT,
            SirdataProduct.SGTM,
            SirdataProduct.AUDIENCE,
            SirdataProduct.CUSTOMER
        ];
    }
}

import {MenuItem} from "@sirdata/ui-lib";
import {pathAccess, pathAccessAccounts, pathAccessApiTokens, pathBilling, pathBillingInfo, pathBillingInvoices, pathCredentials, pathInformation, pathSirdataProducts, pathTraffic, pathTrafficDaily, pathTrafficGlobal} from "./constants";
import {Authorization} from "../api/model/account/Authorization";
import {pathHome} from "../common/utils/constants";

export enum Menu {
    HOME = "home",
    INFORMATION = "information",
    UPDATE_INFORMATION = "update_information",
    SIRDATA_PRODUCTS = "sirdata_products",
    CREDENTIALS = "credentials",
    ACCESS = "access",
    ACCESS_ACCOUNTS = "access_accounts",
    ACCESS_API_TOKENS = "access_api-tokens",
    TRAFFIC = "traffic",
    TRAFFIC_GLOBAL = "traffic_global",
    TRAFFIC_DAILY = "traffic_daily",
    BILLING = "billing",
    BILLING_INFO = "billing_info",
    BILLING_INVOICES = "billing_invoices",
}

export const MENU: MenuItem[] = [
    {label: Menu.HOME, path: pathHome, icon: {name: "home"}},
    {label: Menu.INFORMATION, path: "", icon: {name: "account_circle", outlined: true}, items: [
        {label: Menu.UPDATE_INFORMATION, path: pathInformation, icon: {name: "chevron_right"}},
        {label: Menu.SIRDATA_PRODUCTS, path: pathSirdataProducts, icon: {name: "chevron_right"}}
    ]},
    {label: Menu.CREDENTIALS, path: pathCredentials, icon: {name: "lock_open", outlined: true}},
    {
        label: Menu.ACCESS, path: pathAccess, icon: {name: "manage_accounts", outlined: true}, items: [
            {label: Menu.ACCESS_ACCOUNTS, path: pathAccessAccounts, icon: {name: "chevron_right"}},
            {label: Menu.ACCESS_API_TOKENS, path: pathAccessApiTokens, icon: {name: "chevron_right"}}
        ], adminOnly: true
    },
    {
        label: Menu.TRAFFIC, path: pathTraffic, icon: {name: "route", outlined: true}, allowedTo: Authorization.TRAFFIC, items: [
            {label: Menu.TRAFFIC_GLOBAL, path: pathTrafficGlobal, icon: {name: "chevron_right"}, allowedTo: Authorization.TRAFFIC},
            {label: Menu.TRAFFIC_DAILY, path: pathTrafficDaily, icon: {name: "chevron_right"}, allowedTo: Authorization.TRAFFIC}
        ], adminOnly: true
    },
    {
        label: Menu.BILLING, path: pathBilling, icon: {name: "receipt_long", outlined: true}, items: [
            {label: Menu.BILLING_INFO, path: pathBillingInfo, icon: {name: "chevron_right"}},
            {label: Menu.BILLING_INVOICES, path: pathBillingInvoices, icon: {name: "chevron_right"}}
        ], adminOnly: true
    }
];

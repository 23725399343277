import React, {useState} from "react";
import {AlertSeverity, Box, BoxSpacing, FormLayoutRows, FormLayoutTitle, ToggleSwitch} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {PartnerAccount} from "../../../api/model/account/PartnerAccount";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";

type FormChangePrivacyProps = {
    initPartnerAccount: PartnerAccount;
};

const FormChangePrivacy: React.FC<FormChangePrivacyProps> = ({initPartnerAccount}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textInformation} = useTranslation(TranslationPortalFile.INFORMATION);

    const [allowsSirdataAccess, setAllowsSirdataAccess] = useState(initPartnerAccount.partner.allows_sirdata_access);

    const handleChangeAllowsSirdataAccess = async (value: boolean) => {
        try {
            await session.restPartnerAccount.updateAllowsSirdataAccess(value);

            setAllowsSirdataAccess(value);

            const sessionPartnerAccount = await session.getPartnerAccount();

            session.setPartnerAccount(new PartnerAccount({
                ...sessionPartnerAccount,
                partner: {
                    ...sessionPartnerAccount.partner,
                    allows_sirdata_access: value
                }
            }));

            UIEventManager.alert(textInformation("message.submit_success"), AlertSeverity.SUCCESS);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error.default"), AlertSeverity.DANGER);
            }
        }
    };

    return (
        <Box spacing={BoxSpacing.LARGE}>
            <FormLayoutRows>
                <FormLayoutTitle>{textInformation("partner_privacy_form.title")}</FormLayoutTitle>
                <ToggleSwitch
                    label={textInformation("partner_privacy_form.field.allows_sirdata_access")}
                    checked={allowsSirdataAccess}
                    onChange={handleChangeAllowsSirdataAccess}
                />
            </FormLayoutRows>
        </Box>
    );
};

export default FormChangePrivacy;

import {ApiModel} from "../../../common/api/model/ApiModel";
import {EmailInfo} from "./EmailInfo";
import {Partner} from "./Partner";
import clsx from "clsx";
import {AccountType} from "../../../common/api/model/account/AccountType";
import {CountryCode} from "../../../common/api/model/Country";
import {CountryIsoCode} from "../../../common/api/model/CountryIsoCode";

export class PartnerAccount extends ApiModel {
    email: string = "";
    email_verified?: boolean;
    email_update?: string;
    type: AccountType = AccountType.USER;
    active: boolean | undefined;
    first_name: string = "";
    last_name: string = "";
    phone: string = "";
    phone_region: CountryIsoCode = CountryCode.FRANCE.code;
    private _partner: Partner = new Partner();
    email_info?: EmailInfo;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get displayed_name(): string {
        if (this.first_name || this.last_name) {
            return clsx(this.first_name, this.last_name);
        }
        return "";
    }

    getJsonParameters(): {} {
        return {
            email: this.email,
            email_update: this.email_update,
            type: this.type,
            active: this.active,
            first_name: this.first_name,
            last_name: this.last_name,
            privacy_policy_url: this.privacy_policy_url,
            phone: this.phone,
            phone_region: this.phone_region,
            partner: {
                id: this.partner.id,
                company: this.partner.company,
                country_id: this.partner.country_id
            },
            email_info: this.email_info
        };
    }

    set partner(p: Partner) {
        this._partner = new Partner(p);
    }

    get partner(): Partner {
        return this._partner;
    }

    get emailDomain(): string {
        const domain = this.email.split("@")[1];
        return domain?.toLowerCase() || "";
    }
}

import {ApiModel} from "../ApiModel";

export class PortalSettingProperties extends ApiModel {
    data_selection_id?: number;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
